import { defineAsyncComponent } from "vue";
import * as Sentry from "@sentry/vue";

// craft
import { initClient } from "./api/craft";

// pinia
import { createPinia } from 'pinia'

// css styles
import "swiper/css";
import "./scss/post.scss";
import "./scss/contentHeight.scss";
import 'swiper/css/navigation';
import "swiper/css/pagination";

import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";

// vue
import { createApp } from "vue/dist/vue.esm-bundler.js";
import { createHead } from "@unhead/vue";
import index from "./router/index.ts";
import indexEN from "./router/indexEN.ts";
import routerStore from "./stores/router";
import vuetify from "./vuetify";

// vue components
import App from "./App.vue";
import { Swiper } from "swiper/vue";

// static components
import SwiperPagination from "./components/Slider/SwiperPagination.vue";
import Select from "./components/Select/Select.vue";
import SmallSelect from "./components/Select/SmallSelect.vue";
import SectionElement from "@/pages/Blocks/components/Section.vue";

// import snippets
import Image from "./snippets/Image.vue";
import Video from "./snippets/Video.vue";

// icons
import { HeartIcon as Heart } from "@heroicons/vue/24/outline";
import { HeartIcon } from "@heroicons/vue/24/solid";

// async components

const RichText = defineAsyncComponent(
  () => import("./components/Text/RichText.vue"),
);
const TextImage = defineAsyncComponent(
  () => import("./components/ImageText/TextImage.vue"),
);
const CompactTextImage = defineAsyncComponent(
  () => import("./components/ImageText/CompactTextImage.vue"),
);

const AudioComponent = defineAsyncComponent(
    () => import("@/components/Audio/Audio.vue"),
);
// const HeroSlider = defineAsyncComponent(
//     () => import("@/components/HeroSlider/HeroSlider.vue"),
// );

import HeroSlider from "@/components/HeroSlider/HeroSlider.vue";
import SliderHero from "@/components/HeroSlider/components/Slider.vue";
import { setTranslationTable } from "./stores/translation.ts";

const HeroSmall = defineAsyncComponent(
    () => import("@/components/Hero/HeroSmall.vue"),
);
const Filter = defineAsyncComponent(
    () => import("@/components/Filter/Filter.vue"),
);
const Slider = defineAsyncComponent(
    () => import("@/components/Slider/Slider.vue"),
);
const Community = defineAsyncComponent(
    () => import("@/components/Community/Community.vue"),
);
const Advantages = defineAsyncComponent(
    () => import("@/components/Advantages/Advantages.vue"),
);
const VideoComponent = defineAsyncComponent(
    () => import("@/components/Video/Video.vue"),
);
const TextImageSection = defineAsyncComponent(
    () => import("@/components/ImageText/TextImageSection.vue"),
);
const CompactTextImageSection = defineAsyncComponent(
    () => import("@/components/ImageText/CompactTextImageSection.vue"),
);
const RichTextSection = defineAsyncComponent(
    () => import("@/components/Text/RichTextSection.vue"),
);
const TwoColumns = defineAsyncComponent(
    () => import("@/components/Text/TwoColumns.vue"),
);
const SingleImage = defineAsyncComponent(
    () => import("@/components/Image/SingleImage.vue"),
);
const Images = defineAsyncComponent(
    () => import("@/components/Image/Images.vue"),
);
const Logos = defineAsyncComponent(
    () => import("@/components/Image/Logos.vue"),
);
const Quote = defineAsyncComponent(
    () => import("@/components/Quote/Quote.vue"),
);
const News = defineAsyncComponent(
    () => import("@/components/News/News.vue")
);
const Walkthrough = defineAsyncComponent(
    () => import("@/components/Walkthrough/Walkthrough.vue")
);
const Appointments = defineAsyncComponent(
    () => import("@/components/Appointments/Appointments.vue")
);
const Newsletter = defineAsyncComponent(
    () => import("@/components/ActionBaner/Newsletter.vue"),
);
const Advisor = defineAsyncComponent(
    () => import("@/components/ActionBaner/Advisor.vue"),
);
const CardsSlider = defineAsyncComponent(
    () => import("@/components/Slider/CardsSlider.vue"),
);
const CompactSlider = defineAsyncComponent(
    () => import("@/components/Slider/CompactSlider.vue"),
);
const Cookiebot = defineAsyncComponent(
    () => import("@/components/Cookiebot/Cookiebot.vue"),
);

// async pages
const HousePage = defineAsyncComponent(
    () => import("./pages/House/index.vue"),
);
const HouseHero = defineAsyncComponent(
    () => import("./pages/House/components/Hero/Hero.vue"),
);
const HouseDetails = defineAsyncComponent(
    () => import("./pages/House/components/Details/Details.vue"),
);
const HouseVideo = defineAsyncComponent(
    () => import("./pages/House/components/Video/Video.vue"),
);
const HouseTour = defineAsyncComponent(
    () => import("./pages/House/components/Details/components/HouseTour.vue"),
);
const HouseReferences = defineAsyncComponent(
    () => import("./pages/House/components/References/CardsSlider.vue"),
);
const HouseOffcanvas = defineAsyncComponent(
    () => import("./pages/House/components/ProductOffcanvas.vue"),
);
const HouseActionbar = defineAsyncComponent(
    () => import("./pages/House/components/Action/ActionBar.vue"),
);
const FinderFilter = defineAsyncComponent(
    () => import("./pages/Finder/components/Filter.vue"),
);
const FinderProducts = defineAsyncComponent(
    () => import("./pages/Finder/components/Products.vue"),
);
const WishlistPage = defineAsyncComponent(
    () => import("./pages/Wishlist/index.vue"),
);
const SearchPage = defineAsyncComponent(
    () => import("./pages/Search/index.vue"),
);
const NewsletterPage = defineAsyncComponent(
    () => import("./pages/Newsletter/index.vue"),
);
const ErrorPage = defineAsyncComponent(
    () => import("@/components/Error/Error.vue"),
);
const NewsHeader = defineAsyncComponent(
    () => import("./pages/News/component/NewsHeader.vue"),
);
const AppointmentHeader = defineAsyncComponent(
    () => import("./pages/Appointment/component/appointmentHeader.vue"),
);
const Offcanvas = defineAsyncComponent(
    () => import("./snippets/Offcanvas.vue"),
);


// create app

const token = document.getElementById("token").getAttribute("data-data");
const mode = document.getElementById("token").getAttribute("data-mode");

const translation = document.getElementById("translation").getAttribute("data-lang");

const router = () => {
  if(window.location.pathname.includes("/eng") || window.location.hostname.endsWith("com")){
    routerStore.value = indexEN;
    return indexEN;
  } else {
    routerStore.value = index;
    return index;
  }
}


if (token) {
  const app = createApp();
  const head = createHead();
  const pinia = createPinia();

  setTranslationTable(JSON.parse(translation));

  app.use(head);
  app.use(router());
  app.use(vuetify);
  app.use(pinia);
  // Sentry.init({
  //   app,
  //   dsn: "https://a57caebd16f1d5eb8af4eea0cb4fdd59@o4507644928131072.ingest.de.sentry.io/4507644929507408",
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });

  initClient(token, mode);
  // app.use(craft, {
  //     token: token
  // });

  // app component
  app.component("App", App);

  // icons
  app.component("Heart", Heart);
  app.component("HeartSolid", HeartIcon);

  // snippets
  app.component("Image", Image);
  app.component("Video", Video);
  app.component("SwiperPagination", SwiperPagination);
  app.component("Swiper", Swiper);
  app.component("Select", Select);
  app.component("SmallSelect", SmallSelect);
  app.component("SectionElement", SectionElement);

  //async pages
  app.component("HousePage", HousePage);
  app.component("HouseHero", HouseHero);
  app.component("HouseDetails", HouseDetails);
  app.component("HouseVideo", HouseVideo);
  app.component("HouseTour", HouseTour);
  app.component("HouseReferences", HouseReferences);
  app.component("HouseOffcanvas", HouseOffcanvas);
  app.component("Offcanvas", Offcanvas);
  app.component("HouseActionbar", HouseActionbar);
  app.component("FinderFilter", FinderFilter);
  app.component("FinderProducts", FinderProducts);
  app.component("WishlistPage", WishlistPage);
  app.component("SearchPage", SearchPage);
  app.component("NewsletterPage", NewsletterPage);
  app.component("ErrorPage", ErrorPage);
  app.component("NewsHeader", NewsHeader);
  app.component("AppointmentHeaderComponent", AppointmentHeader);

  // async components
  app.component("AudioComponent", AudioComponent);
  app.component("Walkthrough", Walkthrough);
  app.component("VideoComponent", VideoComponent);
  app.component("Newsletter", Newsletter);
  app.component("Advisor", Advisor);
  app.component("CardsSlider", CardsSlider);
  app.component("TextImage", TextImage);
  app.component("TextImageSection", TextImageSection);
  app.component("CompactTextImageSection", CompactTextImageSection);
  app.component("CompactTextImage", CompactTextImage);
  app.component("HeroSlider", HeroSlider);
  app.component("SliderHero", SliderHero);
  app.component("HeroSmall", HeroSmall);
  app.component("FilterSection", Filter);
  app.component("Slider", Slider);
  app.component("Community", Community);
  app.component("Advantages", Advantages);
  app.component("RichText", RichText);
  app.component("RichTextSection", RichTextSection);
  app.component("TwoColumns", TwoColumns);
  app.component("SingleImage", SingleImage);
  app.component("Images", Images);
  app.component("Quote", Quote);
  app.component("News", News);
  app.component("Logos", Logos);
  app.component("CompactSlider", CompactSlider);
  app.component("Appointments", Appointments);
  app.component("Cookiebot", Cookiebot);

  app.mount("#app");
}
