<template>
  <section v-if="imgs && imgs.length > 0" class="full full-end layout hero relative">
    <!--    TODO Add image Fallback    -->
    <div class="full full-end lg:full lg:full-end">
      <SwiperPagination
        :arrows="false"
        paginationPosition="left"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
        :isLeft="true"
        class="aspect-[1.8] sm:aspect-[2.8] xl:aspect-[3] pagination-left lg:pagination-bg-white"
      >
        <SwiperSlide v-for="img in imgs">
          <Image
            class="w-full h-full object-cover rounded-lg"
            :src="img.url"
            :srcset="img.srcset"
            :alt="img.alt ? img.alt : ''"
            :title="img.title ? img.title : ''"
          />
        </SwiperSlide>
      </SwiperPagination>
    </div>
    <div
      class="p-8 absolute bg-primary max-w-max bottom-4 right-0 rounded-lg z-50 shadow-lg hidden lg:block"
    >
      <h3 class="mb-3" v-if="house" v-text="house.title"></h3>
      <div style="--v-btn-height: 50px" class="flex gap-4">
        <v-btn-lite-dark
          @click="handleClick()"
          :text="`${getTranslationByKey('Informationen anfordern')}`"
        ></v-btn-lite-dark>
        <template v-if="!isCurrentEnglish()">
          <v-btn-lite-dark
            v-if="isLoggedIn"
            :text="`${getTranslationByKey('Datenblatt')}`"
            @click="DownloadDataSheet(+house.id)"
          ></v-btn-lite-dark>
          <v-btn-inverted-light v-else @click="openLogin.open = true">
            <Lock class="w-5" />
            {{ getTranslationByKey('Datenblatt') }}
          </v-btn-inverted-light>
        </template>
        <div class="relative">
          <v-btn-lite-dark-icon
            :rounded="true"
            class="!p-0 w-10 !h-10 !min-w-10"
            @click="handleShare"
          >
            <ShareIcon class="w-5" />
          </v-btn-lite-dark-icon>
          <!-- share group  -->
          <div
            class="share-group-top hidden absolute flex top-0 left-1/2 translate-y-[50px] -translate-x-1/2 gap-4"
          >
            <v-btn-lite-dark-icon
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Facebook teilen')}`"
            >
              <Facebook class="w-5" />
            </v-btn-lite-dark-icon>
            <v-btn-lite-dark-icon
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="MailtoMessage(currentUrl)"
              target="_blank"
              :title="`${getTranslationByKey('Als Email teilen')}`"
            >
              <EnvelopeIcon class="w-5 [&_*]:w-5" />
            </v-btn-lite-dark-icon>
            <v-btn-lite-dark-icon
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://wa.me/?text=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Whatsapp teilen')}`"
            >
              <Whatsapp class="w-5" />
            </v-btn-lite-dark-icon>
            <v-btn-lite-dark-icon
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf LinkedIn teilen')}`"
            >
              <LinkedIn class="w-5" />
            </v-btn-lite-dark-icon>
            <v-btn-lite-dark-icon
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://pinterest.com/pin/create/button/?url=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Pinterest teilen')}`"
            >
              <Pinterest class="w-5 [&_*]:w-5" />
            </v-btn-lite-dark-icon>
          </div>
        </div>

        <template v-if="house">
          <v-btn-lite-dark-icon
            v-if="wishlist && wishlist.wishlistItems.includes(house.id)"
            @click="RemoveFromWishlist(+house.id)"
            icon="mdi-heart"
          ></v-btn-lite-dark-icon>
          <v-btn-lite-dark-icon
            v-else
            @click="AddToWishlist(+house.id)"
            icon="mdi-heart-outline"
          ></v-btn-lite-dark-icon>
        </template>
        <v-btn-lite-dark-icon v-else icon="mdi-loading"></v-btn-lite-dark-icon>
      </div>
    </div>
  </section>
  <section v-else class="full full-end skeleton lg:full lg:full-end">
    <div
      class="full full-end lg:full lg:full-end aspect-[1.8] sm:aspect-[2.8] xl:aspect-[3.55]"
    ></div>
  </section>
</template>
<script setup lang="ts">
import { MailtoMessage } from "../../data/share";
import SwiperPagination from "@/components/Slider/SwiperPagination.vue";
import { SwiperSlide } from "swiper/vue";
import type { Image } from "@/types/elements";
import wishlist from "@/stores/wishlist";
import craft from "@/api/craft";
import errorStore from "@/stores/error";
import LinkedIn from "@/icons/LinkedIn.vue";
import Facebook from "@/icons/Facebook.vue";
import Whatsapp from "@/icons/Whatsapp.vue";
import { ShareIcon, EnvelopeIcon } from "@heroicons/vue/24/outline";
import Pinterest from "@/icons/Pinterest.vue";
import { useUserStore } from "@/stores/userStore";
import { computed } from "vue";
import Lock from "@/icons/Lock.vue";
import { openLogin } from "@/stores/login";
import {getTranslationByKey} from "@/stores/translation";
import initial from "@/stores/initial";
import {isCurrentEnglish} from "@/stores/siteid";

const userStore = useUserStore();

const isLoggedIn = computed(() => userStore.isLoggedIn);

const emit = defineEmits(["openOffcanvas"]);

const props = defineProps<{
  imgs: Image[];
  house: any;
}>();

let currentUrl = document.URL;

function handleClick() {
  if(initial.value) {
    openProductOffcanvasFunction();
  } else {
    emit("openOffcanvas");
  }
}

function openProductOffcanvasFunction() {
  document.getElementById("openProductoffcanvas")?.setAttribute("data-show", "true");
}

function AddToWishlist(id: number) {
  craft.AddToWihlist(id.toString()).then((data: any) => {
    if (data.success && data.action === "add") {
      const list: string[] =
        wishlist.value?.wishlistItems && wishlist.value?.wishlistItems.length > 0
          ? wishlist.value.wishlistItems
          : [];
      list.push(id.toString());
      wishlist.value.wishlistItems = list;
      wishlist.value.wishlistId = data.id;
    } else {
      if (data?.error) {
        errorStore.value.message = data.error;
        errorStore.value.active = true;
      }
    }
  });
}

function RemoveFromWishlist(id: number) {
  craft.RemoveFromWihlist(id.toString()).then((data: any) => {
    if (data.success && data.action === "remove") {
      let list = wishlist.value.wishlistItems;
      list = list.filter((item: any) => item != id);
      wishlist.value.wishlistItems = list;
      wishlist.value.wishlistId = data.id;
    } else {
      if (data?.error) {
        errorStore.value.message = data.error;
        errorStore.value.active = true;
      }
    }
  });
}

function DownloadDataSheet(entryId: number) {
  const form = document.createElement("form");
  form.action = "/actions/_data-sync/data-sheet/download/";
  form.target = "_blank";
  form.method = "POST";

  const input = document.createElement("input");
  input.setAttribute("name", "entryId");
  input.setAttribute("value", entryId.toString());
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

function handleShare() {
  const shareGroup = document.querySelector(".share-group-top");
  shareGroup.classList.toggle("hidden");
}
</script>
