<template>
  <div id="house-detail--actionbar"
    :class="`flex justify-center sm:justify-end fixed z-10 bottom-[80px] right-0 md:bottom-0 md:bg-gray rounded-t-lg w-full lg:min-w-[870px] lg:w-[70%] max-w-[1200px] sm:pr-20 md:px-20 py-3 lg:py-5 sm:left-1/2 sm:-translate-x-1/2 gap-10 lg:gap-20 items-center md:justify-between transition-all duration-300 will-change-[filter]
    ${show ? 'opacity-100 blur-none' : 'opacity-0 blur-sm'}`">
    <h3 class="hidden md:block h4 m-0" v-if="house" v-text="house.title"></h3>
    <div>
      <div style="--v-btn-height: 50px" class="flex gap-4 !text-black">
        <v-btn-inverted
          class="!block sm:!hidden !p-0 w-10 !h-10 !min-w-10"
          @click="handleClick()"
        >
          <InformationCircle class="w-5 !fill-black"></InformationCircle>
        </v-btn-inverted>
        <v-btn-inverted
          class="!hidden sm:!block"
          :text="`${getTranslationByKey('Informationen anfordern')}`"
          @click="handleClick()"
        />

        <v-btn-inverted
          v-if="isLoggedIn"
          class="!block lg:!hidden !p-0 w-10 !h-10 !min-w-10"
          @click="DownloadDataSheet(+house.id)"
        >
          <Clipboard class="w-5"></Clipboard>
        </v-btn-inverted>
        <v-btn-inverted
          v-else
          class="!block lg:!hidden !p-0 w-10 !h-10 !min-w-10"
          @click="openLogin.open = true"
        >
          <Clipboard class="w-5"></Clipboard>
        </v-btn-inverted>
        <template v-if="!isCurrentEnglish()">
          <v-btn-inverted
            v-if="isLoggedIn"
            class="!hidden lg:!block"
            :text="`${getTranslationByKey('Datenblatt')}`"
            @click="DownloadDataSheet(+house.id)"
          />
          <v-btn-inverted v-else class="!hidden lg:!block" @click="openLogin.open = true"
            ><Lock class="w-5" />{{ getTranslationByKey('Datenblatt') }}</v-btn-inverted>
        </template>
        <div class="relative">
          <v-btn-inverted
            :rounded="true"
            class="!p-0 w-10 !h-10 !min-w-10"
            @click="handleShare"
          >
            <ShareIcon class="w-5" />
          </v-btn-inverted>
          <!-- share group  -->
          <div
            class="share-group-bot hidden absolute flex top-0 left-1/2 translate-y-[-50px] -translate-x-1/2 gap-4"
          >
            <v-btn-inverted
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Facebook teilen')}`"
            >
              <Facebook class="w-5" />
            </v-btn-inverted>
            <v-btn-inverted
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="MailtoMessage(currentUrl)"
              target="_blank"
              :title="`${getTranslationByKey('Als Email teilen')}`"
            >
              <EnvelopeIcon class="w-5 [&_*]:w-5" />
            </v-btn-inverted>
            <v-btn-inverted
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://wa.me/?text=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Whatsapp teilen')}`"
            >
              <Whatsapp class="w-5" />
            </v-btn-inverted>
            <v-btn-inverted
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf LinkedIn teilen')}`"
            >
              <LinkedIn class="w-5" />
            </v-btn-inverted>
            <v-btn-inverted
              :rounded="true"
              class="!p-0 w-10 !h-10 !min-w-10 !shadow !shadow-black"
              :href="`https://pinterest.com/pin/create/button/?url=${currentUrl}`"
              target="_blank"
              :title="`${getTranslationByKey('Auf Pinterest teilen')}`"
            >
              <Pinterest class="w-5 [&_*]:w-5" />
            </v-btn-inverted>
          </div>
        </div>

        <template v-if="house">
          <v-btn-inverted
            v-if="wishlist && wishlist.wishlistItems.includes(house.id)"
            :rounded="true"
            class="!p-0 w-10 !min-w-10"
            @click="RemoveFromWishlist(+house.id)"
          >
            <HeartSolid class="w-5" />
          </v-btn-inverted>
          <v-btn-inverted
            v-else
            @click="AddToWishlist(+house.id)"
            :rounded="true"
            class="!p-0 w-10 !min-w-10"
          >
            <Heart class="w-5 stroke-2" />
          </v-btn-inverted>
        </template>
        <v-btn-lite-icon v-else icon="mdi-loading"></v-btn-lite-icon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Whatsapp from "@/icons/Whatsapp.vue";
import wishlist from "@/stores/wishlist";
import craft from "@/api/craft";
import errorStore from "@/stores/error";
import { computed, onMounted, ref } from "vue";
import { ShareIcon, EnvelopeIcon } from "@heroicons/vue/24/outline";
import Clipboard from "@/icons/Clipboard.vue";
import InformationCircle from "@/icons/InformationCircle.vue";
import Facebook from "@/icons/Facebook.vue";
import LinkedIn from "@/icons/LinkedIn.vue";
import { openLogin } from "../../../../stores/login";
import Pinterest from "@/icons/Pinterest.vue";
import { useUserStore } from "@/stores/userStore";
import Lock from "@/icons/Lock.vue";
import { MailtoMessage } from "../../data/share";
import {getTranslationByKey} from "@/stores/translation";
import initial from "@/stores/initial";
import {isCurrentEnglish} from "@/stores/siteid";

const userStore = useUserStore();
const isLoggedIn = computed(() => userStore.isLoggedIn);

const emit = defineEmits(["openOffcanvas"]);

const props = defineProps<{
  house: any;
}>();

let currentUrl = document.URL;
let show = ref(window.innerWidth < 1024);

window.addEventListener('scroll', hideOnFooter);
window.onresize = () => (show.value = window.innerWidth < 1024);

function hideOnFooter() {
  let footerOffset =
      document.getElementById("footer").offsetTop - window.innerHeight - 75;
  if (
      (window.scrollY < 100 && window.innerWidth >= 1024) ||
      window.scrollY > footerOffset
  ) {
    show.value = false;
  } else {
    show.value = true;
  }
}

function handleClick() {
  if(initial.value) {
    openProductOffcanvasFunction();
  } else {
    emit("openOffcanvas");
  }
}

function openProductOffcanvasFunction() {
  document.getElementById("openProductoffcanvas")?.setAttribute("data-show", "true");
}

function AddToWishlist(id: number) {
  craft.AddToWihlist(id.toString()).then((data: any) => {
    if (data.success && data.action === "add") {
      const list: string[] =
        wishlist.value?.wishlistItems && wishlist.value?.wishlistItems.length > 0
          ? wishlist.value.wishlistItems
          : [];
      list.push(id.toString());
      wishlist.value.wishlistItems = list;
      wishlist.value.wishlistId = data.id;
    } else {
      if (data?.error) {
        errorStore.value.message = data.error;
        errorStore.value.active = true;
      }
    }
  });
}

function RemoveFromWishlist(id: number) {
  craft.RemoveFromWihlist(id.toString()).then((data: any) => {
    if (data.success && data.action === "remove") {
      let list = wishlist.value.wishlistItems;
      list = list.filter((item: any) => item != id);
      wishlist.value.wishlistItems = list;
      wishlist.value.wishlistId = data.id;
    } else {
      if (data?.error) {
        errorStore.value.message = data.error;
        errorStore.value.active = true;
      }
    }
  });
}

function DownloadDataSheet(entryId: number) {
  const form = document.createElement("form");
  form.action = "/actions/_data-sync/data-sheet/download/";
  form.target = "_blank";
  form.method = "POST";

  const input = document.createElement("input");
  input.setAttribute("name", "entryId");
  input.setAttribute("value", entryId.toString());
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

function handleShare() {
  const shareGroup = document.querySelector(".share-group-bot");
  shareGroup.classList.toggle("hidden");
}
</script>
